@import "animate.css";
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
textarea {
  resize: none;
}
.text-area-wrap {
  position: relative;
}
.text-area-wrap .error-message {
  position: absolute;
  top: 100%;
  left: 8px;
  color: #c30303;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
}
.input-wrap {
  position: relative;
}
.input-wrap .error-message {
  position: absolute;
  top: 38px;
  left: 8px;
  color: #c30303;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
}
@media screen and (max-width: 1249px) {
  .input-wrap .error-message {
    position: absolute;
    top: 34px;
    left: 8px;
    color: #c30303;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
  }
}
@media screen and (max-width: 1249px) {
  .content {
    width: 320px;
    margin: auto;
    padding: 0 15px;
  }
}
@media screen and (min-width: 1250px) {
  .content {
    width: 1200px;
    margin: auto;
    padding: 0 0;
  }
}
.fullWidth {
  width: 100%;
}
.medium-14 {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
}
.fullscreen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}
@media screen and (min-width: 1250px) {
  .fullscreen .login-container {
    width: 50%;
    padding: 50px;
    background-color: #3c3d3e;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fullscreen .login-container h1 {
    text-align: center;
    color: #9f9f9f;
  }
  .fullscreen .login-container p {
    text-align: center;
    color: #9f9f9f;
  }
  .fullscreen .login-container .error-message {
    color: #c05a17;
  }
  .fullscreen .login-container input {
    height: 50px;
    border: none;
    background-color: #fff;
    border-radius: 10px;
    width: 60%;
    margin: 5px auto;
    text-align: center;
    color: #9f9f9f;
    font-size: 20px;
  }
  .fullscreen .login-container button {
    width: 100px;
    height: 50px;
    background-color: #76b637;
    color: #fff;
    border-color: transparent;
    border-radius: 10px;
    margin: 20px auto;
  }
}
@media screen and (max-width: 1249px) {
  .fullscreen .login-container {
    max-width: 300px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #3c3d3e;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fullscreen .login-container h1 {
    text-align: center;
    color: #9f9f9f;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .fullscreen .login-container p {
    text-align: center;
    color: #9f9f9f;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    margin: 10px 0 0 0;
  }
  .fullscreen .login-container .error-message {
    color: #c05a17;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
    padding: 0;
    margin: 20px 0 0 0;
  }
  .fullscreen .login-container input:first-of-type {
    margin-top: 22px;
  }
  .fullscreen .login-container input {
    height: 40px;
    border: none;
    background-color: #fff;
    border-radius: 10px;
    width: 240px;
    margin: 8px auto;
    text-align: center;
    color: #404040;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
  }
  .fullscreen .login-container button {
    width: 100px;
    height: 40px;
    background-color: #76b637;
    color: #fff;
    border-color: transparent;
    border-radius: 10px;
    margin: 20px auto;
  }
}
.dashboard {
  width: 100%;
  min-height: 100vh;
  background: #000;
}
.dashboard .header {
  max-width: 1200px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px 3px;
  background: #000;
  height: 100px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
}
.dashboard .header .logo {
  width: 200px;
}
.dashboard .header .signout button {
  border: none;
  border-radius: 5px;
  background-color: #76b637;
  color: #fff;
  padding: 10px 20px;
}
.dashboard .content {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 100px;
}
.dashboard .content h1 {
  color: #fff;
  padding-bottom: 40px;
}
.dashboard .content .cars-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 280px;
  grid-gap: 20px;
}
@media screen and (max-width: 1249px) {
  .dashboard .content .cars-container {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 250px;
    grid-gap: 20px;
  }
}
.dashboard .content .cars-container .car {
  width: 80%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px 3px;
  border-radius: 30px;
}
.dashboard .content .cars-container .car .car-name {
  color: #78b435;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  margin: 0;
}
.dashboard .content .cars-container .car .car-image {
  width: 60%;
  margin: 0 auto;
}
.dashboard .content .cars-container .car .car-image .loading-container {
  width: 60px;
  margin: auto;
}
.dashboard .content .cars-container .car .price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.dashboard .content .cars-container .car .car-price {
  color: #404040;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  line-height: 30px;
}
.dashboard .content .cars-container .car .car-price .price-value {
  font-family: "Roboto-Regular", sans-serif;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.15;
  letter-spacing: 0.08px;
  color: #404040;
}
.dashboard .content .cars-container .car .edit-mode {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 5px;
  width: 60%;
}
.dashboard .content .cars-container .car .edit-mode input {
  width: 100%;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #404040;
  background: transparent;
  font-size: 20px;
  height: 28px;
  padding-left: 10px;
}
.dashboard .content .cars-container .car .edit-mode button {
  height: 30px;
  border: none;
  color: #fff;
  background: #76b637;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 15px;
}
.dashboard .content .cars-container .car .price-edit-icon {
  color: #404040;
  font-size: 30px;
  padding-left: 10px;
}
.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading-container .loader {
  width: 60px;
  margin: 0;
}
.loading-container .text {
  text-align: center;
  color: #9f9f9f;
}
.fullscreen-loading {
  height: 100vh;
}
@media only screen and (max-width: 1249px) {
  .home .section-top {
    background: #000000;
    width: 100%;
    height: 817px;
    padding-top: 60px;
  }
  .home .section-top .section-top-content {
    max-width: 320px;
    margin: auto;
    padding: 0 15px;
    height: 720px;
    display: flex;
    flex-direction: column;
  }
  .home .section-top .section-top-content .car-container {
    height: 248px;
    width: 100%;
  }
  .home .section-top .section-top-content .car-container .img-container {
    position: absolute;
    left: 50%;
    transform: translateX(-160px);
    width: 320px;
    height: 230px;
  }
  .home .section-top .section-top-content .car-container .img-container img {
    width: 100%;
    height: 100%;
  }
  .home .section-top .section-top-content .text-reservation-wrap {
    flex-grow: 1;
    position: relative;
  }
  .home .section-top .section-top-content .text-reservation-wrap .text-container {
    position: absolute;
    bottom: 340px;
    width: 290px;
    padding-bottom: 20px;
  }
  .home .section-top .section-top-content .text-reservation-wrap .text-container h1 {
    padding: 0;
    margin: 0;
    color: #ffffff;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .home .section-top .section-top-content .text-reservation-wrap .text-container .green {
    color: #78b435;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .home .section-top .section-top-content .text-reservation-wrap .reservation-container {
    position: absolute;
    height: 340px;
    width: 290px;
    background-color: #3d3d3d;
    bottom: -1px;
    left: 0;
  }
  .home .section-top .bottom-bar {
    height: 37px;
    width: 100%;
    background-color: #3d3d3d;
  }
  .home .section-contact {
    background: #efefef;
    padding: 20px;
  }
  .home .section-contact .tel {
    text-decoration: none;
    color: #78b435;
  }
  .home .section-contact .tel:hover {
    text-decoration: underline;
  }
  .home .section-contact > div {
    max-width: 320px;
    margin: auto;
  }
  .home .section-contact > div h2 {
    text-align: center;
    margin: 0;
    color: #404040;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: -0.06px;
  }
  .home .section-contact > div h2 .green {
    color: #78b435;
  }
}
@media only screen and (min-width: 1250px) {
  .home .section-top {
    background: #000000;
    width: 100%;
    height: 687px;
    padding-top: 80px;
  }
  .home .section-top .section-top-content {
    width: 1200px;
    margin: auto;
    height: 570px;
    display: flex;
    flex-direction: row-reverse;
  }
  .home .section-top .section-top-content .car-container {
    position: relative;
    width: 910px;
  }
  .home .section-top .section-top-content .car-container .img-container {
    position: absolute;
    width: 900px;
    height: 570px;
    right: -100px;
  }
  .home .section-top .section-top-content .car-container .img-container img {
    width: 100%;
    height: 100%;
  }
  .home .section-top .section-top-content .text-reservation-wrap {
    position: relative;
    height: 570px;
    width: 290px;
  }
  .home .section-top .section-top-content .text-reservation-wrap .text-container {
    position: absolute;
    bottom: 340px;
    width: 290px;
    padding-bottom: 15px;
  }
  .home .section-top .section-top-content .text-reservation-wrap .text-container h1 {
    padding: 0;
    margin: 0;
    color: #ffffff;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .home .section-top .section-top-content .text-reservation-wrap .text-container .green {
    color: #78b435;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .home .section-top .section-top-content .text-reservation-wrap .reservation-container {
    position: absolute;
    height: 340px;
    width: 290px;
    background-color: #3d3d3d;
    bottom: -1px;
    left: 0;
  }
  .home .section-top .bottom-bar {
    height: 37px;
    width: 100%;
    background-color: #3d3d3d;
  }
  .home .section-contact {
    background: #efefef;
    padding: 60px 0;
  }
  .home .section-contact .tel {
    text-decoration: none;
    color: #78b435;
  }
  .home .section-contact .tel:hover {
    text-decoration: underline;
  }
  .home .section-contact .break {
    display: none;
  }
  .home .section-contact > div h2 {
    text-align: center;
    margin: 0;
    color: #404040;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
  }
  .home .section-contact > div h2 .green {
    color: #78b435;
  }
}
.home .reservation-container {
  padding: 22px 20px;
}
.home .reservation-container .item {
  position: relative;
  margin-top: 20px;
}
.home .reservation-container .item:first-of-type {
  margin-top: 0;
}
.home .reservation-container .item .calendar-icon {
  position: absolute;
  right: 8px;
  z-index: 1;
  top: 12px;
}
.home .reservation-container .item .next {
  width: 100%;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  background-color: #78b435;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.services-container {
  background-color: #efefef;
}
@media only screen and (max-width: 1249px) {
  .services-container .services-content {
    width: 100%;
    padding: 20px;
    margin: auto;
    max-width: 320px;
  }
  .services-container .services-content h2 {
    margin: 0;
    padding: 20px 0;
    color: #404040;
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 27px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .services-container .services-content h2 span {
    color: #78b435;
  }
  .services-container .services-content .services-desktop {
    display: none;
  }
  .services-container .services-content .services-mobile {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }
  .services-container .services-content .services-mobile .row {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .services-container .services-content .services-mobile .row:first-of-type {
    margin-top: 0;
  }
  .services-container .services-content .services-mobile .row .service {
    align-items: center;
    margin: 0 20px;
    position: relative;
    height: 130px;
  }
  .services-container .services-content .services-mobile .row .service span {
    position: absolute;
    padding-top: 20px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    width: 100px;
    left: -25px;
    text-align: center;
    color: #404040;
  }
}
@media only screen and (min-width: 1250px) {
  .services-container .services-content {
    width: 1200px;
    margin: auto;
  }
  .services-container .services-content h2 {
    margin: 0;
    padding: 60px 0;
    color: #404040;
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 27px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .services-container .services-content h2 span {
    color: #78b435;
  }
  .services-container .services-content .services-mobile {
    display: none;
  }
  .services-container .services-content .services-desktop {
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
  }
  .services-container .services-content .services-desktop .service {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .services-container .services-content .services-desktop .service span {
    padding-top: 20px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    width: 120px;
    text-align: center;
    color: #404040;
  }
}
@keyframes makeNavVisible {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
@keyframes makeNavHidden {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #000000;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
@media screen and (max-width: 1249px) {
  .header-container {
    height: 60px;
  }
  .header-container .nav-desktop {
    display: none;
  }
  .header-container .nav-mobile {
    position: fixed;
    width: 100%;
    background: #000000;
    top: 60px;
    bottom: 0;
  }
  .header-container .nav-mobile .nav-mobile-content ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .header-container .nav-mobile .nav-mobile-content ul li {
    margin: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #222222;
    border-bottom: solid 1px #222222;
    color: #ffffff;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
  }
  .header-container .nav-mobile .nav-mobile-content ul li a {
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-container .nav-mobile .nav-mobile-content ul li a:hover {
    color: #78b435;
  }
  .header-container .nav-mobile .nav-mobile-content ul .language-options .distancer {
    width: 15px;
  }
  .header-container .nav-mobile .nav-mobile-content ul .language-options button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    outline: none;
    cursor: pointer;
  }
  .header-container .nav-mobile .nav-mobile-content ul .language-options .language-option-selected {
    color: #78b435;
  }
  .header-container .nav-visible {
    animation-name: makeNavVisible;
    animation-duration: 0.2s;
    left: 0;
  }
  .header-container .nav-hidden {
    animation-name: makeNavHidden;
    animation-duration: 0.2s;
    left: -100%;
  }
  .header-container .hamburger {
    display: flex;
  }
}
@media screen and (min-width: 1250px) {
  .header-container {
    height: 80px;
  }
  .header-container .nav-mobile {
    display: none;
  }
  .header-container .hamburger {
    display: none;
  }
  .header-container .nav-desktop {
    align-items: center;
  }
  .header-container .nav-desktop ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
  }
  .header-container .nav-desktop ul li {
    color: #ffffff;
    margin-left: 20px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    display: flex;
    align-items: center;
  }
  .header-container .nav-desktop ul li a {
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
  }
  .header-container .nav-desktop ul li a:hover {
    color: #78b435;
  }
  .header-container .nav-desktop ul li .language-selection-dropdown {
    position: relative;
  }
  .header-container .nav-desktop ul li .language-selection-dropdown button {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    outline: none;
    cursor: pointer;
  }
  .header-container .nav-desktop ul li .language-selection-dropdown span {
    margin-left: 5px;
  }
  .header-container .nav-desktop ul li .language-selection-dropdown .language-option-selected {
    color: #78b435;
  }
  .header-container .nav-desktop ul li .language-selection-dropdown .language-option {
    position: absolute;
    top: 22px;
    left: 0;
  }
  .header-container .nav-desktop ul li .language-selection-dropdown .language-option-hidden {
    display: none;
  }
}
.header-container .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 1200px;
}
.header-container .header-content .logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 1249px) {
  .footer {
    background: #000000;
    height: 280px;
    width: 100%;
  }
  .footer .content {
    max-width: 320px;
    margin: auto;
    padding: 36px 20px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
    justify-content: flex-start;
  }
  .footer .content .section-left {
    display: flex;
    flex-direction: column;
  }
  .footer .content .section-left .companyName {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #ffffff;
  }
  .footer .content .section-left .link a {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    text-decoration: none;
    color: #78b435;
  }
  .footer .content .section-right {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer .content .section-right .contact {
    display: flex;
    align-items: center;
  }
  .footer .content .section-right .contact a {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    text-decoration: none;
    color: #78b435;
    margin-left: 5px;
  }
}
@media only screen and (min-width: 1250px) {
  .footer {
    background: #000000;
    height: 90px;
    width: 100%;
  }
  .footer .content {
    width: 1200px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }
  .footer .content .section-left .companyName {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #ffffff;
  }
  .footer .content .section-left .link {
    margin-left: 25px;
  }
  .footer .content .section-left .link a {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    text-decoration: none;
    color: #78b435;
  }
  .footer .content .section-right {
    display: flex;
    justify-content: flex-end;
  }
  .footer .content .section-right .contact {
    display: flex;
    align-items: center;
    margin-left: 25px;
  }
  .footer .content .section-right .contact a {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    text-decoration: none;
    color: #78b435;
    margin-left: 8px;
  }
}
.dropdown {
  width: 100%;
  height: 40px;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  cursor: pointer;
}
.dropdown:focus {
  outline: none;
}
.dropdown.visible {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown .dropdown-selected {
  padding: 0 8px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown .dropdown-selected .text {
  color: #919191;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown .dropdown-selected .text.selected {
  color: #404040;
}
.dropdown .dropdown-selected .text.error {
  color: #e65e29;
}
.dropdown .collapsed {
  display: none;
}
.dropdown .visible {
  display: block;
}
.dropdown .dropdown-options-container {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border: none;
  position: absolute;
  border-top: 1px solid #e2e2e2;
  top: 39px;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: #fff;
  z-index: 100;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dropdown .dropdown-options-container ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dropdown .dropdown-options-container ul li {
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  color: #404040;
  height: 40px;
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dropdown .dropdown-options-container ul li.selected {
  background-color: #78b435;
  color: #fff;
}
.dropdown .dropdown-options-container ul li:hover {
  background-color: #78b435;
  color: #fff;
}
.react-datepicker-wrapper {
  cursor: pointer;
  width: 100%;
  height: 40px;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
  height: 100%;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
  border: none;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  background: transparent;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  border: none;
  padding: 0 8px;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  color: #404040;
  cursor: pointer;
}
.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 100;
  left: -35px !important;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker {
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--keyboard-selected,
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__month-text--keyboard-selected,
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__quarter-text--keyboard-selected {
  background-color: transparent;
  color: #404040;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
  background-color: #78b435;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--today {
  border: 2px solid #78b435;
  border-radius: 5px;
  font-weight: 400;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
  background-color: #fff;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 5px;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  color: #404040;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
  margin-top: 10px;
  color: #78b435;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
  color: #78b435;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 10px;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__header--time {
  display: none;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__time-container {
  position: absolute;
  left: 102%;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #78b435;
}
.datepicker-placeholder {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 0 8px;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  color: #919191;
}
@media screen and (max-width: 1249px) {
  .fleet-container .fleet-content {
    width: 280px;
    margin: auto;
  }
  .fleet-container .fleet-content .filter-section {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d3d3d3;
  }
  .fleet-container .fleet-content .filter-section h3 {
    text-align: center;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    margin: 0 0 5px 0;
    padding: 0;
    color: #404040;
  }
  .fleet-container .fleet-content .filter-section .filter ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #919191;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .fleet-container .fleet-content .filter-section .filter ul li {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    display: flex;
    align-items: center;
    border-right: 1px solid #d3d3d3;
    height: 30px;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }
  .fleet-container .fleet-content .filter-section .filter ul li.selected {
    color: #78b435;
    font-weight: 700;
  }
  .fleet-container .fleet-content .filter-section .filter ul li:hover {
    color: #78b435;
  }
  .fleet-container .fleet-content .filter-section .filter ul li:nth-of-type(4) {
    border-right: none;
    padding-right: 0px;
  }
  .fleet-container .fleet-content .filter-section .filter ul li:last-of-type {
    border-right: none;
    padding-right: 0px;
  }
  .fleet-container .fleet-content .loading-container {
    padding: 15px 0;
  }
  .fleet-container .fleet-content .cars-container .fleet-car {
    border-bottom: 1px solid #d3d3d3;
  }
  .fleet-container .fleet-content .cars-container .fleet-car:last-of-type {
    border-bottom: none;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .image-container {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .image-container img {
    width: 200px;
    height: 130px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top {
    border-bottom: 1px solid #d3d3d3;
    color: #404040;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .name {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .type {
    display: inline-block;
    color: #78b435;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    text-transform: capitalize;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .pipe {
    height: 40px;
    border-left: 2px solid #d3d3d3;
    margin: 0 10px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .price {
    display: none;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom {
    flex-direction: column;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-desktop {
    display: none;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-mobile {
    display: flex;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-mobile ul {
    list-style-type: none;
    margin: 0;
    padding: 12px 0;
    color: #404040;
    border-bottom: 1px solid #d3d3d3;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-mobile ul:first-of-type {
    width: 70%;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-mobile ul:last-of-type {
    flex-grow: 1;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-mobile ul li {
    display: flex;
    width: auto;
    margin: 5px 0;
    align-items: center;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-mobile ul li span {
    margin-left: 10px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 30px 0;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .right .price {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .right .price span {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .right .btnQuote {
    width: 160px;
    height: 40px;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 10px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 1250px) {
  .fleet-container .fleet-content {
    width: 995px;
    margin: auto;
    padding-bottom: 60px;
  }
  .fleet-container .fleet-content .filter-section {
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #d3d3d3;
  }
  .fleet-container .fleet-content .filter-section h3 {
    text-align: center;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    margin: 0;
    padding: 0;
    color: #404040;
  }
  .fleet-container .fleet-content .filter-section .filter {
    display: flex;
    align-items: center;
  }
  .fleet-container .fleet-content .filter-section .filter ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #919191;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .fleet-container .fleet-content .filter-section .filter ul li {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.06px;
    display: flex;
    align-items: center;
    border-right: 1px solid #d3d3d3;
    height: 30px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
  }
  .fleet-container .fleet-content .filter-section .filter ul li.selected {
    color: #78b435;
    font-weight: 700;
  }
  .fleet-container .fleet-content .filter-section .filter ul li:hover {
    color: #78b435;
  }
  .fleet-container .fleet-content .filter-section .filter ul li:last-of-type {
    border-right: none;
    padding-right: 0px;
  }
  .fleet-container .fleet-content .loading-container {
    padding: 30px 0;
  }
  .fleet-container .fleet-content .cars-container .fleet-car {
    width: 100%;
    height: 240px;
    border-bottom: 1px solid #d3d3d3;
    display: flex;
    height: 100%;
    align-items: center;
  }
  .fleet-container .fleet-content .cars-container .fleet-car:last-of-type {
    border-bottom: none;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .image-container {
    width: 310px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .image-container img {
    width: 310px;
    height: 210px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container {
    flex-grow: 1;
    height: 100%;
    padding-left: 100px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
    color: #404040;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .name {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .type {
    display: inline-block;
    color: #78b435;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
    text-transform: capitalize;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .pipe {
    height: 40px;
    border-left: 2px solid #d3d3d3;
    margin: 0 10px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .price {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .top .price span {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom {
    display: flex;
    height: 170px;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 30px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-mobile {
    display: none;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-desktop ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #404040;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.06px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-desktop ul li {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .left-desktop ul li span {
    margin-left: 10px;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .right .price {
    display: none;
  }
  .fleet-container .fleet-content .cars-container .fleet-car .info-container .bottom .right .btnQuote {
    width: 160px;
    height: 40px;
    border-radius: 5px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 1249px) {
  .quote .section-top {
    height: 315px;
    padding-top: 60px;
    background-color: #000;
  }
  .quote .section-top .quote-content {
    max-width: 320px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .quote .section-top .quote-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
  }
  .quote .section-top .quote-content h2 {
    color: #78b435;
    margin: 10px 0 0 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
    width: 90%;
  }
  .quote .section-top .quote-content .telephone {
    color: #78b435;
    margin: 0 0 0 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
  }
  .quote main .quote-content {
    width: 320px;
    margin: auto;
  }
  .quote main .quote-content .loading-wrap {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .quote main .quote-content .get-quote .description {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    text-align: center;
    margin: 0;
    padding: 20px 0;
    color: #404040;
  }
  .quote main .quote-content .get-quote .reservation-container {
    width: 100%;
    border-top: 1px solid #d3d3d3;
    display: flex;
    padding-bottom: 60px;
    flex-direction: column;
  }
  .quote main .quote-content .get-quote .reservation-container .main {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 20px;
  }
  .quote main .quote-content .get-quote .reservation-container .main .car-container {
    margin: auto;
    border-bottom: 1px solid #d3d3d3;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form {
    width: 240px;
    margin: auto;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .input-wrap {
    margin-top: 22px;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .text-area-container {
    margin-top: 30px;
    border-radius: 5px;
    width: 240px;
    height: 150px;
    border: none;
    background: #fff;
    box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .text-area-container .additionalNote {
    border-radius: 5px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    width: 240px;
    height: 150px;
    border: none;
    outline: none;
    padding: 8px;
    background: transparent;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .text-area-container .additionalNote:focus {
    border: 1px solid #78b435;
  }
  .quote main .quote-content .get-quote .reservation-container .extras {
    width: 100%;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #d3d3d3;
  }
  .quote main .quote-content .get-quote .reservation-container .extras .info {
    padding: 20px 0;
  }
  .quote main .quote-content .get-quote .reservation-container .extras .info p {
    text-align: center;
    color: #919191;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }
  .quote main .quote-content .get-quote .reservation-container .extras .select {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  .quote main .quote-content .get-quote .reservation-container .finish {
    height: 80px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price {
    position: relative;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price .location-charge {
    position: absolute;
    margin-top: 10px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #404040;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price span {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #404040;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    line-height: 24px;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price span .price {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.15;
    letter-spacing: 0.08px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .submit {
    width: 120px;
    height: 35px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}
@media screen and (min-width: 1250px) {
  .quote .section-top {
    height: 375px;
    padding-top: 80px;
    background-color: #000;
  }
  .quote .section-top .quote-content {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .quote .section-top .quote-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
  }
  .quote .section-top .quote-content h2 {
    color: #78b435;
    margin: 10px 0 0 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: -0.06px;
    width: 700px;
  }
  .quote .section-top .quote-content .telephone {
    color: #78b435;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: -0.06px;
  }
  .quote main .quote-content {
    width: 1200px;
    margin: auto;
  }
  .quote main .quote-content .loading-wrap {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .quote main .quote-content .get-quote .description {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    margin: 0;
    padding: 20px 0;
    color: #404040;
    text-align: left;
  }
  .quote main .quote-content .get-quote .reservation-container {
    width: 100%;
    border-top: 1px solid #d3d3d3;
    display: flex;
    padding-bottom: 80px;
    flex-direction: column;
  }
  .quote main .quote-content .get-quote .reservation-container .main {
    border-bottom: 1px solid #d3d3d3;
    display: flex;
  }
  .quote main .quote-content .get-quote .reservation-container .main .car {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form {
    padding: 20px 0 20px 60px;
    flex-grow: 1;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .text-area-container {
    margin-top: 30px;
    border-radius: 5px;
    width: 100%;
    height: 90px;
    border: none;
    background: #fff;
    box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .text-area-container .additionalNote {
    border-radius: 5px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    width: 100%;
    height: 90px;
    border: none;
    outline: none;
    padding: 8px;
    background: transparent;
  }
  .quote main .quote-content .get-quote .reservation-container .main .form .text-area-container .additionalNote:focus {
    border: 1px solid #78b435;
  }
  .quote main .quote-content .get-quote .reservation-container .extras {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
  }
  .quote main .quote-content .get-quote .reservation-container .extras .info {
    width: 25%;
  }
  .quote main .quote-content .get-quote .reservation-container .extras .info p {
    color: #919191;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .quote main .quote-content .get-quote .reservation-container .extras .select {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 60px;
    grid-gap: 30px;
  }
  .quote main .quote-content .get-quote .reservation-container .finish {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price {
    position: relative;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price .location-charge {
    position: absolute;
    margin-top: 10px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 14px;
    text-align: center;
    color: #404040;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price span {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #404040;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
    line-height: 40px;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .price span .price {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
    font-size: 40px;
    padding-left: 10px;
    padding-right: 5px;
  }
  .quote main .quote-content .get-quote .reservation-container .finish .submit {
    width: 120px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}
.car-container {
  width: 280px;
}
.car-container .image-container {
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1250px) {
  .car-container .image-container {
    justify-content: flex-start;
  }
}
.car-container .image-container img {
  width: 200px;
  height: 130px;
}
.car-container .info .top {
  border-bottom: 1px solid #d3d3d3;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
@media screen and (min-width: 1250px) {
  .car-container .info .top {
    justify-content: flex-start;
  }
}
.car-container .info .top .name {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
}
.car-container .info .top .type {
  display: inline-block;
  color: #78b435;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  text-transform: capitalize;
}
.car-container .info .top .pipe {
  height: 40px;
  border-left: 2px solid #d3d3d3;
  margin: 0 10px;
}
.car-container .info .bottom {
  display: flex;
}
.car-container .info .bottom ul {
  list-style-type: none;
  margin: 0;
  padding: 12px 0;
  color: #404040;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
}
.car-container .info .bottom ul:first-of-type {
  width: 70%;
}
.car-container .info .bottom ul:last-of-type {
  flex-grow: 1;
}
.car-container .info .bottom ul li {
  display: flex;
  width: auto;
  margin: 5px 0;
  align-items: center;
}
.car-container .info .bottom ul li span {
  margin-left: 10px;
}
.datepicker-holder {
  width: 240px;
  display: inline-block;
  position: relative;
}
.datepicker-holder .calendar-icon {
  position: absolute;
  right: 8px;
  z-index: 1;
  top: 12px;
}
.extra-container {
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100px;
  color: #404040;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  display: flex;
  padding: 10px;
}
.extra-container .left {
  width: 25%;
  padding-top: 10px;
}
@media screen and (max-width: 1249px) {
  .extra-container .left {
    width: 20%;
  }
  .extra-container .left svg {
    width: 22px;
  }
}
.extra-container .right {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1249px) {
  .extra-container .right {
    width: 80%;
  }
}
.extra-container .right .price {
  height: 25px;
  width: 100%;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
}
.extra-container .right .price .calculation {
  margin-left: 2px;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  line-height: 14px;
}
.extra-container .right .bottom {
  width: 100%;
  display: flex;
  color: white;
}
.extra-container .right .bottom .minus,
.extra-container .right .bottom .plus,
.extra-container .right .bottom .amount {
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  background-color: #bcbcbc;
}
.extra-container .right .bottom .plus {
  cursor: pointer;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #78b435;
}
.extra-container .right .bottom .minus {
  cursor: pointer;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
}
.extra-container .right .bottom .amount {
  background-color: transparent;
  color: #404040;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
}
.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #78b435;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #78b435;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.text-input-container {
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 5px;
  width: 240px;
  height: 40px;
}
.text-input-container .text-input {
  background: transparent;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  border: none;
  padding: 0 8px;
  color: #404040;
  outline: none;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
}
.text-input-container .text-input:focus {
  outline: 1px solid #78b435;
}
.text-input-container .text-input::-webkit-input-placeholder {
  /* Edge */
  color: #919191;
}
.text-input-container .text-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #919191;
}
.text-input-container .text-input::placeholder {
  color: #919191;
}
.dropdown-input {
  width: 240px;
  height: 40px;
  display: inline-block;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  border: none;
  box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.dropdown-input:focus {
  border: 1px solid #78b435;
  outline: none;
}
.dropdown-input.visible {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-input .dropdown-selected {
  padding: 0 8px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown-input .dropdown-selected input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
}
.dropdown-input .dropdown-selected input:active {
  border: none;
}
.dropdown-input .dropdown-selected .text {
  color: #919191;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-input .dropdown-selected .text.selected {
  color: #404040;
}
.dropdown-input .dropdown-selected .text.error {
  color: #e65e29;
}
.dropdown-input .collapsed {
  display: none;
}
.dropdown-input .visible {
  display: block;
}
.dropdown-input .dropdown-options-container {
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  border: none;
  position: absolute;
  border-top: 1px solid #e2e2e2;
  top: 39px;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: #fff;
  z-index: 100;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dropdown-input .dropdown-options-container ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dropdown-input .dropdown-options-container ul li {
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  color: #404040;
  height: 40px;
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dropdown-input .dropdown-options-container ul li.selected {
  background-color: #78b435;
  color: #fff;
}
.dropdown-input .dropdown-options-container ul li:hover {
  background-color: #78b435;
  color: #fff;
}
@media screen and (max-width: 1249px) {
  .locations .section-top {
    height: 315px;
    padding-top: 60px;
    background-color: #000;
  }
  .locations .section-top .locations-content {
    max-width: 320px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .locations .section-top .locations-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
  }
  .locations .section-top .locations-content h2 {
    color: #78b435;
    margin: 10px 0 0 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
    width: 90%;
  }
  .locations .main-container .top {
    width: 100%;
  }
  .locations .main-container .top .content {
    width: 320px;
    margin: auto;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .locations .main-container .top .content .item {
    display: flex;
    align-items: center;
  }
  .locations .main-container .top .content .item span {
    margin-left: 5px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 14px;
    color: #404040;
  }
  .locations .main-container .top .content .item svg {
    height: 20px;
    width: 18px;
  }
  .locations .main-container .top .content .divider {
    margin-left: 10px;
    margin-right: 10px;
    border-left: 1px solid #d3d3d3;
    height: 30px;
  }
  .locations .main-container .map-container {
    width: 100%;
    background-color: #efefef;
  }
  .locations .main-container .map-container .content {
    width: 100%;
    margin: auto;
    height: 400px;
    padding: 0;
  }
  .locations .main-container .bottom {
    width: 100%;
  }
  .locations .main-container .bottom .content {
    width: 320px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 0;
  }
  .locations .main-container .bottom .content .location {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }
  .locations .main-container .bottom .content .location .icon svg {
    width: 15px;
    height: 15px;
  }
  .locations .main-container .bottom .content .location .name {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    color: #404040;
    margin-left: 10px;
  }
  .locations .main-container .bottom .content .location .name:hover {
    font-size: 16px;
  }
  .locations .main-container .bottom .content .location .fee {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
    color: #78b435;
    margin-left: 10px;
  }
  .locations .main-container .bottom .just-drop-off p {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    color: #404040;
    margin: 25px 0 25px 0;
    text-align: left;
  }
}
@media screen and (min-width: 1250px) {
  .locations .section-top {
    height: 375px;
    padding-top: 80px;
    background-color: #000;
  }
  .locations .section-top .locations-content {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .locations .section-top .locations-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
  }
  .locations .section-top .locations-content h2 {
    color: #78b435;
    margin: 10px 0 0 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: -0.06px;
    width: 700px;
  }
  .locations .main-container .top {
    width: 100%;
  }
  .locations .main-container .top .content {
    width: 1200px;
    margin: auto;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .locations .main-container .top .content .item {
    display: flex;
    align-items: center;
  }
  .locations .main-container .top .content .item span {
    margin-left: 5px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
    color: #404040;
  }
  .locations .main-container .top .content .item svg {
    height: 20px;
    width: 18px;
  }
  .locations .main-container .top .content .divider {
    margin-left: 10px;
    margin-right: 10px;
    border-left: 1px solid #d3d3d3;
    height: 30px;
  }
  .locations .main-container .map-container {
    width: 100%;
    background-color: #efefef;
  }
  .locations .main-container .map-container .content {
    width: 1200px;
    margin: auto;
    height: 500px;
  }
  .locations .main-container .bottom {
    width: 100%;
  }
  .locations .main-container .bottom .content {
    width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 30px 0;
  }
  .locations .main-container .bottom .content .location {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }
  .locations .main-container .bottom .content .location:hover {
    transform: scale(1.05);
  }
  .locations .main-container .bottom .content .location .icon svg {
    width: 15px;
    height: 15px;
  }
  .locations .main-container .bottom .content .location .name {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    color: #404040;
    margin-left: 10px;
  }
  .locations .main-container .bottom .content .location .name:hover {
    font-size: 16px;
  }
  .locations .main-container .bottom .content .location .fee {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
    color: #78b435;
    margin-left: 10px;
  }
  .locations .main-container .bottom .just-drop-off p {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    color: #404040;
    margin: 25px 0 25px 0;
    text-align: left;
  }
}
.map-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.map-info .heading {
  margin: 0;
  padding: 0;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  line-height: 16px;
  color: #78b435;
}
.map-info .pickup {
  margin-top: 10px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  color: #404040;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.map-info .return {
  margin-top: 5px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  color: #404040;
  justify-content: flex-start;
  align-items: center;
}
.map-info .check {
  color: #78b435;
  margin-left: 10px;
}
.map-info .times {
  color: #e40707;
  margin-left: 10px;
}
.map-info .fee {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  margin-top: 10px;
  background-color: #78b435;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-10deg);
  border-radius: 10px;
  min-width: 100px;
  padding: 0 7px;
  color: #fff;
  height: 30px;
}
.map-info .fee.charge {
  background-color: orange;
}
.map-info .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
@media screen and (max-width: 1249px) {
  .contact .section-top {
    height: 315px;
    padding-top: 60px;
    background-color: #000;
  }
  .contact .section-top .contact-content {
    max-width: 320px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .contact .section-top .contact-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
  }
  .contact .section-top .contact-content h2 {
    color: #78b435;
    margin: 10px 0 0 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    line-height: 16px;
    width: 90%;
  }
  .contact .main-container .contact-content {
    width: 320px;
    margin: auto;
  }
  .contact .main-container .contact-content .about {
    width: 100%;
    padding: 40px 0 30px 0;
    border-bottom: 1px solid #d3d3d3;
  }
  .contact .main-container .contact-content .about h3 {
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
    color: #78b435;
    margin-bottom: 20px;
  }
  .contact .main-container .contact-content .about p {
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .contact .main-container .contact-content .info {
    width: 100%;
    padding: 30px 0 60px 0;
    border-bottom: 1px solid #d3d3d3;
  }
  .contact .main-container .contact-content .info h3 {
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
    color: #78b435;
  }
  .contact .main-container .contact-content .info .data {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .contact .main-container .contact-content .info .data div {
    margin-top: 10px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
    display: flex;
    flex-direction: column;
  }
  .contact .main-container .contact-content .info .data div .margin-right {
    margin-right: 8px;
  }
  .contact .main-container .contact-content .info .data div .icon {
    display: inline-block;
  }
  .contact .main-container .contact-content .info .data div .link {
    text-decoration: none;
    color: #404040;
  }
  .contact .main-container .contact-content .contact {
    width: 100%;
    padding: 30px 0 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .contact .main-container .contact-content .contact h3 {
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
    color: #78b435;
  }
  .contact .main-container .contact-content .contact .form {
    width: 100%;
    position: relative;
    padding-top: 40px;
  }
  .contact .main-container .contact-content .contact .form .envelope-mobile {
    position: absolute;
    top: 0px;
    right: 0;
  }
  .contact .main-container .contact-content .contact .form .envelope-mobile svg {
    width: 60px;
    height: 60px;
  }
  .contact .main-container .contact-content .contact .form .left {
    width: 320px;
  }
  .contact .main-container .contact-content .contact .form .left .row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .contact .main-container .contact-content .contact .form .left .input-wrap {
    margin-top: 22px;
  }
  .contact .main-container .contact-content .contact .form .left .input-wrap div {
    width: 100%;
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap {
    margin-top: 30px;
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap .text-area {
    width: 320px;
    height: 150px;
    border-radius: 5px;
    border: none;
    background: #fff;
    box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap .text-area .message {
    border-radius: 5px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    width: 320px;
    height: 150px;
    border: none;
    outline: none;
    background: transparent;
    padding: 8px;
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap .text-area .message:focus {
    border: 1px solid #78b435;
  }
  .contact .main-container .contact-content .contact .form .left .btn-holder {
    width: 100%;
    display: flex;
    padding-top: 20px;
    justify-content: flex-end;
  }
  .contact .main-container .contact-content .contact .form .left .btn-holder .send {
    width: 120px;
    height: 35px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .contact .main-container .contact-content .contact .form .right {
    display: none;
  }
}
@media screen and (min-width: 1250px) {
  .contact .section-top {
    height: 375px;
    padding-top: 80px;
    background-color: #000;
  }
  .contact .section-top .contact-content {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .contact .section-top .contact-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
  }
  .contact .section-top .contact-content h2 {
    color: #78b435;
    margin: 10px 0 0 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: -0.06px;
    width: 700px;
  }
  .contact .main-container .contact-content {
    width: 1200px;
    margin: auto;
  }
  .contact .main-container .contact-content .about {
    width: 100%;
    padding: 50px 0 30px 0;
    border-bottom: 1px solid #d3d3d3;
  }
  .contact .main-container .contact-content .about h3 {
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
    color: #78b435;
    margin-bottom: 20px;
  }
  .contact .main-container .contact-content .about p {
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .contact .main-container .contact-content .info {
    width: 100%;
    padding: 30px 0 60px 0;
    border-bottom: 1px solid #d3d3d3;
  }
  .contact .main-container .contact-content .info h3 {
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
    color: #78b435;
  }
  .contact .main-container .contact-content .info .data {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .contact .main-container .contact-content .info .data div {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
    display: flex;
    flex-direction: column;
  }
  .contact .main-container .contact-content .info .data div .margin-right {
    margin-right: 8px;
  }
  .contact .main-container .contact-content .info .data div .icon {
    display: inline-block;
  }
  .contact .main-container .contact-content .info .data div .link {
    text-decoration: none;
    color: #404040;
  }
  .contact .main-container .contact-content .contact {
    width: 100%;
    padding: 30px 0 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .contact .main-container .contact-content .contact h3 {
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.08px;
    color: #78b435;
  }
  .contact .main-container .contact-content .contact .form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
  }
  .contact .main-container .contact-content .contact .form .envelope-mobile {
    display: none;
  }
  .contact .main-container .contact-content .contact .form .left {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 820px;
  }
  .contact .main-container .contact-content .contact .form .left .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap {
    margin-top: 30px;
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap .text-area {
    width: 820px;
    height: 150px;
    border-radius: 5px;
    border: none;
    outline: none;
    background: #fff;
    box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap .text-area .message {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    width: 820px;
    height: 150px;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 8px;
    background: transparent;
  }
  .contact .main-container .contact-content .contact .form .left .text-area-wrap .text-area .message:focus {
    border: 1px solid #78b435;
  }
  .contact .main-container .contact-content .contact .form .left .btn-holder {
    width: 100%;
    display: flex;
    padding-top: 20px;
    justify-content: flex-end;
  }
  .contact .main-container .contact-content .contact .form .left .btn-holder .send {
    width: 120px;
    height: 35px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .contact .main-container .contact-content .contact .form .right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }
}
@media screen and (max-width: 1249px) {
  .terms .section-top {
    height: 315px;
    padding-top: 60px;
    background-color: #000;
  }
  .terms .section-top .terms-content {
    max-width: 320px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .terms .section-top .terms-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
  }
  .terms .main-container .terms-content {
    width: 320px;
    margin: auto;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .terms .main-container .terms-content h2 {
    text-align: left;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    color: #78b435;
  }
  .terms .main-container .terms-content p {
    text-align: left;
    margin: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .terms .main-container .terms-content li {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .terms .main-container .terms-content a {
    color: #404040;
    text-decoration: underline;
  }
}
@media screen and (min-width: 1250px) {
  .terms .section-top {
    height: 375px;
    padding-top: 80px;
    background-color: #000;
  }
  .terms .section-top .terms-content {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .terms .section-top .terms-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
  }
  .terms .main-container .terms-content {
    width: 1200px;
    margin: auto;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .terms .main-container .terms-content h2 {
    text-align: left;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: -0.06px;
    color: #78b435;
  }
  .terms .main-container .terms-content p {
    text-align: left;
    margin: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .terms .main-container .terms-content li {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .terms .main-container .terms-content a {
    color: #404040;
    text-decoration: underline;
  }
}
@media screen and (max-width: 1249px) {
  .privacy .section-top {
    height: 315px;
    padding-top: 60px;
    background-color: #000;
  }
  .privacy .section-top .privacy-content {
    max-width: 320px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .privacy .section-top .privacy-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
  }
  .privacy .main-container .privacy-content {
    width: 320px;
    margin: auto;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .privacy .main-container .privacy-content h2 {
    text-align: left;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    color: #78b435;
  }
  .privacy .main-container .privacy-content p {
    text-align: left;
    margin: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .privacy .main-container .privacy-content li {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .privacy .main-container .privacy-content a {
    color: #404040;
    text-decoration: underline;
  }
}
@media screen and (min-width: 1250px) {
  .privacy .section-top {
    height: 375px;
    padding-top: 80px;
    background-color: #000;
  }
  .privacy .section-top .privacy-content {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .privacy .section-top .privacy-content h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
  }
  .privacy .main-container {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .privacy .main-container .privacy-content {
    width: 1200px;
    margin: auto;
  }
  .privacy .main-container h2 {
    text-align: left;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;
    letter-spacing: -0.06px;
    color: #78b435;
  }
  .privacy .main-container p {
    text-align: left;
    margin: 0;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .privacy .main-container li {
    font-family: "Roboto-Regular", sans-serif;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    color: #404040;
  }
  .privacy .main-container a {
    color: #404040;
    text-decoration: underline;
  }
}
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .modal-backdrop .modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}
.modal .modal-backdrop .modal-content p {
  font-family: "Roboto-Regular", sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: -0.06px;
  line-height: 32px;
  text-align: center;
  margin-top: 40px;
  color: #fff;
}
@media screen and (max-width: 1249px) {
  .modal .modal-backdrop .modal-content p {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    line-height: 30px;
    margin-top: 20px;
  }
}
.modal-visible {
  display: block;
}
.modal-hidden {
  display: none;
}
.svg-status {
  width: 80px;
  display: block;
  margin: 0 auto 0;
}
@media screen and (max-width: 1249px) {
  .svg-status {
    width: 60px;
  }
}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
}
p.success {
  color: #73af55;
}
p.error {
  color: #af0c0c;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.transfers-container {
  background-color: #3d3d3d;
}
@media only screen and (max-width: 1249px) {
  .transfers-container .transfers-content {
    width: 100%;
    padding: 20px;
    margin: auto;
    max-width: 320px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .transfers-container .transfers-content .image {
    margin-top: 60px;
    margin-bottom: 30px;
    width: 300px;
  }
  .transfers-container .transfers-content .text {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .transfers-container .transfers-content .text h2 {
    width: 320px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
    color: #FFF;
    text-align: center;
  }
  .transfers-container .transfers-content .text h2 .highlight {
    color: #78b435;
  }
  .transfers-container .transfers-content .text button {
    width: 150px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .transfers-container .transfers-content .text button a {
    text-decoration: none;
    color: #fff;
  }
}
@media only screen and (min-width: 1250px) {
  .transfers-container .transfers-content {
    width: 1200px;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .transfers-container .transfers-content .image {
    width: 450px;
  }
  .transfers-container .transfers-content .text {
    flex-grow: 1;
    padding-left: 120px;
    justify-content: flex-start;
  }
  .transfers-container .transfers-content .text h2 {
    width: 512px;
    font-family: "Roboto-Regular", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.1px;
    font-size: 42px;
    color: #FFF;
  }
  .transfers-container .transfers-content .text h2 .highlight {
    color: #78b435;
  }
  .transfers-container .transfers-content .text button {
    width: 150px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05px;
    background-color: #78b435;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .transfers-container .transfers-content .text button a {
    text-decoration: none;
    color: #fff;
  }
}
